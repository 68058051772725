<template>
  <div class="bg-grey">
    <b-container fluid>
      <!-- Navbar :: start -->
      <b-row v-if="!this.$cookies.get('company_id')">
        <b-col col md="12">
          <NavbarCompNew />
        </b-col>
      </b-row>
      <MainHeader v-if="this.$cookies.get('company_id')" />

      <!-- Navbar :: end -->
      <b-row class="pt-6 mt-5 px-md-3 pb-3">
        <b-col class="col-md-12 bg-white rounded-lg py-3">
          <h1 class="border-bottom text-center fmp-b py-3">SUPPORT TICKET REQUEST</h1>
          <div>
            <h5 class="text-center py-3">Please let us know how we can assist you and we will get back to you shortly</h5>
            <ValidationObserver ref="form">
              <div class="customized-form support-form">
                <input type="hidden" name="_cc" value="ruchitha.deshpande@binarynumbers.io" />
                <div>
                  <ValidationProvider name="userName" rules="required" v-slot="{ errors, classes }">
                    <label>Name</label>
                    <b-form-input placeholder="Name" v-model="formData.name" :class="classes"> </b-form-input>

                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div>
                  <ValidationProvider name="email" rules="required|email" v-slot="{ errors, classes }">
                    <label>Email</label>
                    <b-form-input placeholder="E-mail" v-model="formData.email" :class="classes"> </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div>
                  <ValidationProvider name="role" rules="required" v-slot="{ errors, classes }">
                    <label>I'm an..</label>
                    <multiselect class="multis mb-3" :class="classes" v-model="formData.role" placeholder="I'm an.."
                      label="text" track-by="id" :options="roles" :multiple="false" :allow-empty="false" />

                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div>
                  <ValidationProvider name="message" rules="required" v-slot="{ errors, classes }">
                    <label>Message</label>
                    <b-textarea placeholder="Message" name="Message" rows="6" class="h-75" v-model="formData.message"
                      :class="classes"> </b-textarea>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="mb-3">
                  <vue-recaptcha :sitekey="reCaptchaSiteKey" @verify="captchaVerify" @expired="captchaExpired"
                    @error="captchaError"></vue-recaptcha>
                </div>
                <button id="submit" class="btn btn-primary" @click="validateInput">Submit</button>
              </div>
            </ValidationObserver>

            <!-- <form
              target="_blank"
              :action="`https://formsubmit.co/${SupportTeamEmail}`"
              method="POST"
            >
            <div class="form-group">
              <div class="form-row">
                <div class="col">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    placeholder="Full Name"
                    required
                    v-model="formData.name"
                  />
                </div>
                <div class="col">
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    placeholder="Email Address"
                    required
                    v-model="formData.email"
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    name="role"
                    class="form-control"
                    placeholder="Role"
                    required
                    v-model="formData.role"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <textarea
                placeholder="Your Message"
                class="form-control"
                name="message"
                rows="10"
                required
                v-model="formData.message"
              ></textarea>
            </div>
            <button
              @click="supportRequest"
              type="submit"
              class="btn btn-lg btn-dark btn-block"
            >
              Submit Form
            </button>
            <input type="hidden" name="_captcha" value="false" />
            </form> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- <div v-if="confirm_html" v-html="confirm_html" id="confirm-not-spam"></div> -->
  </div>
</template>

<script>
import UserService from "@/apiServices/UserService";
import { SupportTeamEmail } from "@/helpers/constant";
import axios from "axios";
import { reCaptchaSiteKey } from "@/config";

import VueRecaptcha from "vue-recaptcha";

import Multiselect from "vue-multiselect";
import NavbarCompNew from "../components/shared/NavbarCompNew.vue";
import MainHeader from "@/views/partials/MainHeader.vue";

export default {
  components: {
    Multiselect,
    VueRecaptcha,
    NavbarCompNew,
    MainHeader
  },
  data() {
    return {
      captchaValue: null,
      reCaptchaSiteKey: reCaptchaSiteKey,
      // confirm_html: null,
      SupportTeamEmail: SupportTeamEmail,
      formData: {
        name: null,
        email: null,
        message: null,
        role: null,
      },
      roles: [
        {
          id: "Applicant",
          text: "I’m an applicant",
        },
        {
          id: "Employer",
          text: "I’m an employer",
        },
      ],
    };
  },
  methods: {
    captchaVerify(event) {
      console.log(`verify event`, event);
      this.captchaValue = event;
    },
    captchaExpired() {
      this.captchaValue = null;
    },
    captchaError() {
      this.captchaValue = null;
    },
    validateInput() {
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (this.captchaValue) {
            this.supportRequest();
          } else {
            this.$toasted.show("Please verify captcha", { duration: 6000 });
            this.captchaValue = null;
          }
          // this.supportRequest();
        } else {
          this.$toasted.show("Some fields are missing!", { duration: 6000 });
        }
      });
    },
    // async onSubmit() {
    //   const payload = this.formData;
    //   var config = {
    //     method: "POST",
    //     url: `https://formsubmit.co/prafful.gautam@binarynumbers.io`,
    //     payload,
    //   };
    //   axios(config)
    //     .then((res) => {
    //       
    //       this.confirm_html = res.data;
    //       console.log("this.confirm_html :>> ", this.confirm_html);
    //     })
    //     .catch((err) => {
    //       
    //     });
    // },

    async supportRequest() {
      let loader = this.$loading.show();
      try {
        let response = await UserService.supportRequest({
          name: this.formData.name,
          email: this.formData.email,
          message: this.formData.message,
          role: this.formData.role.id,
          captcha: this.captchaValue,
        });

        const { data } = response;
        loader.hide();
        if (data.status) {
          this.$toasted.show("Success!", {
            duration: 6000,
            icon: "check",
          });
          this.captchaValue = null;
          this.formData = {
            name: null,
            email: null,
            message: null,
            role: null,
          }
          setTimeout(() => {
            location.reload();
          }, 1000)
        } else {
          this.$toasted.show(data.message || "Failed!", {
            duration: 6000,
            icon: "clear",
          });
          this.captchaValue = null;
        }
      } catch (e) {
        console.log(`e`, e);
        loader.hide();
        this.$toasted.show("Failed!", {
          duration: 6000,
          icon: "clear",
        });
        this.captchaValue = null;
      }
    },
  },
};
</script>

<style scoped>
.support-form {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width:768px) {
  .support-form {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

#submit {
  font-family: mp-medium;
  font-size: 13px;
  /* border-radius: 20px; */
  padding: 08px 22px;
  background: #6244e5 !important;
  color: white;
  border: 1px solid #6244e5;
  margin-bottom: 0;
}

.multis>>>.multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}

.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multis>>>.multiselect__tag-icon:after {
  color: white;
}

.multis>>>.multiselect__tag-icon:hover {
  background: #6244e5;
}

.multis>>>.multiselect__option--highlight {
  background: #6244e5;
}

.multis>>>.multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multiselect__single {
  background: #eaeaea !important;
}

.multiselect-dropdown span {
  margin-right: 10px;
}
</style>
